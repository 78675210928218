import { Outlet } from "react-router-dom";

import Navbar from "./../Components/Partials/Navbar";
import Footer from "./../Components/Partials/Footer";
import beeXShopAD from "./../Components/Media/Shop/beexshop-homepage-ad.gif";


function Root() {

    return (
        <div>
            <Navbar />
            
                <Outlet />
                {/* <a href="https://beexplorer.shop" target="_blank">
                    <img src={beeXShopAD} className="beeXShopAD" />
                </a> */}
            
            <Footer />
        </div>
    );
};

export default Root;